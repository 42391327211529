<div class="matero-container-wrap"
  [class.matero-sidenav-collapsed]="options.sidenavCollapsed && options.navPos !== 'top'"
  [class.matero-navbar-side]="options.navPos === 'side'"
  [class.matero-navbar-top]="options.navPos === 'top'"
  [class.matero-header-above]="options.headerPos === 'above'"
  [class.matero-header-fixed]="options.headerPos === 'fixed'"
  [dir]="options.dir">

  <ng-progress />

  <!-- Header Above -->
  @if (options.showHeader && options.headerPos === 'above') {
    <app-header
      (toggleSidenav)="sidenav.toggle()"
      (toggleSidenavNotice)="sidenavNotice.toggle()"
      [showBranding]="true" />
  }

  <mat-sidenav-container class="matero-container" autosize autoFocus>
    <mat-sidenav #sidenav class="matero-sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()">
      <app-sidebar
        [showToggle]="!isOver"
        [showUser]="options.showUserPanel"
        [showHeader]="options.headerPos !== 'above'"
        [toggleChecked]="options.sidenavCollapsed"
        (toggleCollapsed)="toggleCollapsed()"
        (closeSidenav)="sidenav.close()" />
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over">
      <app-sidebar-notice />
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content">
      @if (options.showHeader && options.headerPos !== 'above') {
        <app-header
          [showToggle]="!options.sidenavCollapsed && options.navPos !== 'top'"
          [showBranding]="options.navPos === 'top'"
          (toggleSidenav)="sidenav.toggle()"
          (toggleSidenavNotice)="sidenavNotice.toggle()" />
      }

      <main class="matero-page-content">
        <router-outlet />
      </main>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
