<div class="customizer-handle" matTooltip="Drag me around"
     cdkDrag (cdkDragStarted)="onDragStart($event)">
  <button mat-fab (click)="openPanel(customizerPanel)">
    <mat-icon aria-label="Example icon-button with a settings icon">settings</mat-icon>
  </button>
</div>

<ng-template #customizerPanel>
  <h2 class="d-flex align-items-center justify-content-between m-t-0 f-w-400">
    <span>Settings</span>
    <button mat-icon-button (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <h3 class="f-w-400">Theme</h3>
  <form [formGroup]="form">
    <div class="m-l-8">
      <mat-radio-group formControlName="theme">
        <mat-radio-button class="m-x-4" value="light">Light</mat-radio-button>
        <mat-radio-button class="m-x-4" value="dark">Dark</mat-radio-button>
        <mat-radio-button class="m-x-4" value="auto">Auto</mat-radio-button>
      </mat-radio-group>
    </div>
    <h3 class="f-w-400">Header</h3>
    <div class="m-l-8">
      <mat-slide-toggle formControlName="showHeader"
                        [disableControl]="isHeaderPosAbove || isNavPosTop">Visible</mat-slide-toggle>
      <h4 class="f-w-400">Position</h4>
      <mat-radio-group formControlName="headerPos">
        <mat-radio-button class="m-x-4" value="above"
                          [disabled]="!isShowHeader || isNavPosTop">Above</mat-radio-button>
        <mat-radio-button class="m-x-4" value="fixed">Fixed</mat-radio-button>
        <mat-radio-button class="m-x-4" value="static">Static</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-divider inset class="m-x-0 m-y-24" />
    <h3 class="f-w-400">Navigation</h3>
    <div class="m-l-8">
      <mat-slide-toggle formControlName="showUserPanel">Show User Panel</mat-slide-toggle>
      <h4 class="f-w-400">Position</h4>
      <mat-radio-group formControlName="navPos">
        <mat-radio-button class="m-x-4" value="side">Side</mat-radio-button>
        <mat-radio-button class="m-x-4" value="top"
                          [disabled]="isHeaderPosAbove || !isShowHeader">Top</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-divider inset class="m-x-0 m-y-24" />
    <h3 class="f-w-400">Direction</h3>
    <div class="m-l-8">
      <mat-radio-group formControlName="dir">
        <mat-radio-button class="m-x-4" value="ltr">LTR</mat-radio-button>
        <mat-radio-button class="m-x-4" value="rtl">RTL</mat-radio-button>
      </mat-radio-group>
    </div>
  </form>
</ng-template>
