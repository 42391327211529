<mat-toolbar>
  @if (showToggle) {
    <button mat-icon-button (click)="toggleSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  }

  @if (showBranding) {
    <app-branding [showName]="showBrandName"/>
  }

  <span class="flex-fill"></span>

  <app-translate />

</mat-toolbar>
