
<ul navAccordion class="matero-sidemenu level-0" [class.submenu]="false" [@expansion]="'expanded'">
  <li navAccordionItem class="menu-item"
      routerLinkActive="active" [route]="'/home'" [type]="'link'">
    <a class="menu-heading" [routerLink]="'/home'"
       matRipple [matRippleDisabled]="!ripple">
      <mat-icon class="menu-icon">home</mat-icon>
      <span class="menu-name">{{'home' | translate}}</span>
    </a>
  </li>
  <li navAccordionItem class="menu-item"
      routerLinkActive="active" [route]="'/estimates'" [type]="'sub'">
    <button navAccordionToggle class="menu-heading menu-toggle"
            matRipple [matRippleDisabled]="!ripple" (click)="toggleEstimations()">
        <mat-icon class="menu-icon">insert_chart</mat-icon>
        <span class="menu-name">{{'estimates' | translate}}</span>
        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </button>
    <ul navAccordion class="matero-sidemenu level-1" [class.submenu]="true" [@expansion]="estimationsExpand">
      <li navAccordionItem class="menu-item"
          routerLinkActive="active" [route]="'estimates/new'" [type]="'link'">
        <a class="menu-heading" [routerLink]="'estimates/new'"
           matRipple [matRippleDisabled]="!ripple">
          <mat-icon class="menu-icon">add_circle</mat-icon>
          <span class="menu-name">{{'estimates.new' | translate}}</span>
        </a>
      </li>
      <li navAccordionItem class="menu-item"
          routerLinkActive="active" [route]="'estimates/suggestions'" [type]="'link'">
        <a class="menu-heading" [routerLink]="'estimates/suggestions'"
           matRipple [matRippleDisabled]="!ripple">
          <mat-icon class="menu-icon">flag</mat-icon>
          <span class="menu-name">{{'estimates.suggestions' | translate}}</span>
        </a>
      </li>
    </ul>
  </li>
  <li navAccordionItem class="menu-item"
      routerLinkActive="active" [route]="'team'" [type]="'link'">
    <a class="menu-heading" [routerLink]="'team'"
       matRipple [matRippleDisabled]="!ripple">
      <mat-icon class="menu-icon">group</mat-icon>
      <span class="menu-name">{{'team' | translate}}</span>
    </a>
  </li>
</ul>

