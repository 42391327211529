import * as i0 from '@angular/core';
import { InjectionToken, inject, Directive } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router } from '@angular/router';
import { of, tap, delay, filter, switchMap } from 'rxjs';
import * as i1 from 'ngx-progressbar';
import { NgProgressRef } from 'ngx-progressbar';
const defaultRouterOptions = {
  minDuration: 0,
  startEvents: [NavigationStart],
  completeEvents: [NavigationEnd, NavigationCancel, NavigationError]
};
const NG_PROGRESS_ROUTER_OPTIONS = new InjectionToken('NG_PROGRESS_ROUTER_OPTIONS', {
  providedIn: 'root',
  factory: () => defaultRouterOptions
});
function provideNgProgressRouter(options) {
  return {
    provide: NG_PROGRESS_ROUTER_OPTIONS,
    useValue: {
      ...defaultRouterOptions,
      ...options
    }
  };
}

/**
 * Check if a router event type exists in an array of router event types
 */
function eventExists(routerEvent, events) {
  let res = false;
  events.map(event => res = res || routerEvent instanceof event);
  return res;
}
class NgProgressRouterBase {
  constructor() {
    this.router = inject(Router);
    this.progressRef = inject(NgProgressRef, {
      host: true,
      self: true
    });
    this.config = inject(NG_PROGRESS_ROUTER_OPTIONS);
  }
  ngOnInit() {
    const startProgress = of({}).pipe(tap(() => this.progressRef.start()));
    const completeProgress = of({}).pipe(delay(this.config.minDuration), tap(() => this.progressRef.complete()));
    const filterEvents = [...this.config.startEvents, ...this.config.completeEvents];
    this.subscription = this.router.events.pipe(filter(event => eventExists(event, filterEvents)), switchMap(event => eventExists(event, this.config.startEvents) ? startProgress : completeProgress)).subscribe();
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
  static {
    this.ɵfac = function NgProgressRouterBase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgProgressRouterBase)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgProgressRouterBase
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressRouterBase, [{
    type: Directive
  }], null, null);
})();
class NgProgressRouter extends NgProgressRouterBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgProgressRouter_BaseFactory;
      return function NgProgressRouter_Factory(__ngFactoryType__) {
        return (ɵNgProgressRouter_BaseFactory || (ɵNgProgressRouter_BaseFactory = i0.ɵɵgetInheritedFactory(NgProgressRouter)))(__ngFactoryType__ || NgProgressRouter);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgProgressRouter,
      selectors: [["ng-progress", "ngProgressRouter", ""]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressRouter, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-progress[ngProgressRouter]'
    }]
  }], null, null);
})();
class NgProgressRouterRef extends NgProgressRouterBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgProgressRouterRef_BaseFactory;
      return function NgProgressRouterRef_Factory(__ngFactoryType__) {
        return (ɵNgProgressRouterRef_BaseFactory || (ɵNgProgressRouterRef_BaseFactory = i0.ɵɵgetInheritedFactory(NgProgressRouterRef)))(__ngFactoryType__ || NgProgressRouterRef);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgProgressRouterRef,
      selectors: [["", "ngProgressRouter", "", 5, "ng-progress"]],
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.NgProgressRef]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressRouterRef, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngProgressRouter]:not(ng-progress)',
      hostDirectives: [NgProgressRef]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NG_PROGRESS_ROUTER_OPTIONS, NgProgressRouter, NgProgressRouterRef, provideNgProgressRouter };
